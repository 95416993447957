<template>
  <div class="home">
    <!-- 首页banner -->
    <div class="home_banner">
      <!-- banner内容 -->
      <div class="home_b_content">
        <p class="home_content_title">智能填报</p>
        <!-- 填报批次 -->
        <div class="batch">填报批次：<el-select size="small" v-model="select" slot="prepend" placeholder="请选择"
            @change="isMileageCardOrder">
            <el-option label="常规批" value="1"></el-option>
          </el-select>
        </div>
        <!-- 选择科目 -->
        <div class="choose">
          <div class="content_label">
            选择科目:
          </div>
          <div class="input_content">
            <div class="label_input" v-for="(item, index) in tall" :key="index">
              <label :for="index" style="display:flex;align-items: center;justify-content: space-around;">
                <input type="checkbox" name="" :id="index" :value="item.name" v-model="Arr" :disabled='Arr.length >= 3'
                  @change="FNcheck($event, index)">{{ item.name }}</label>
            </div>
            <el-button @click="back">重置</el-button>
          </div>
        </div>
        <!-- 填报批次 -->
        <div class="batch">
          <div class="input_batch">
            <el-input type="number" placeholder="请输入分数" v-model="valNum" />
          </div>
        </div>
        <!-- 开始填报按钮 -->
        <div class="btn" @click="handle">开始填报</div>
      </div>
      <!-- banner下方list -->
      <div class="home_b_lists">
        <div class="home_b_list" v-for="(item, index) in bannerList" :key="index"
          @click="routerjump(item.url, item.open)">
          <img :src="item.imgUrl">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 首页内容 -->
    <div class="home_content">
      <!-- 高考咨询 -->
      <div class="gkzx">
        <div class="gkzx_title">高考咨询</div>
        <!-- tab切换控制 -->
        <div class="tab">
          <div class="tab_button">
            <div v-for="(item, index) in tabList" :key="index" :class="{ active: currentClass == index }"
              @click="toggleTab(index)">{{ item.dicName }}</div>
          </div>
        </div>
        <div>
          <!-- tab切换内容 -->
          <div class="tab_con" v-show="currentTab == 0">
            <div class="tab_con_list" v-for="(item, index) in newlist" :key="index">
              <img class="tab_con_list_img" src="@/assets/home/zx.png" alt="">
              <div class="tab_con_cons">
                <div class="tab_cons_title">
                  <img src="@/assets/home/tab_title_img.png" alt="">
                  <p @click="jump(item.id)">{{ item.title }}</p>
                </div>
                <span class="tab_cons_data">{{ item.postDate || '' }}</span>
              </div>
            </div>
          </div>
          <div class="tab_con" v-show="currentTab == 1">
            <div class="tab_con_list" v-for="(item, index) in newlist" :key="index">
              <img class="tab_con_list_img" src="@/assets/home/zx.png" alt="">
              <div class="tab_con_cons">
                <div class="tab_cons_title">
                  <img src="@/assets/home/tab_title_img.png" alt="">
                  <p @click="jump(item.id)">{{ item.title }}</p>
                </div>
                <span class="tab_cons_data">{{ item.postDate || '' }}</span>
              </div>
            </div>
          </div>
          <div class="tab_con" v-show="currentTab == 2">
            <div class="tab_con_list" v-for="(item, index) in newlist" :key="index">
              <img class="tab_con_list_img" src="@/assets/home/zx.png" alt="">
              <div class="tab_con_cons">
                <div class="tab_cons_title">
                  <img src="@/assets/home/tab_title_img.png" alt="">
                  <p @click="jump(item.id)">{{ item.title }}</p>
                </div>
                <span class="tab_cons_data">{{ item.postDate || '' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 测评体系 -->
      <div class="cptx">
        <div class="cptx_title">测评体系</div>
        <div class="tab_con">
          <div class="cptx_cons" v-for="(item, index) in TestList" :key="index">
            <p>{{ item.eval_Table.evalName }}</p>
            <div class="cptxhtml" v-html="item.eval_Table.evalintroduction"></div>
            <div class="cptx_cons_bottom">{{ item.eval_Table.evalName }}</div>
            <div class="card">
              <div class="cardbtn" @click="go(item.eval_Table.id, item.eval_Table.evalName, item.isTest)">开始测评</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 专家咨询 -->
      <div class="zjzx">
        <div class="zjzx_title">专家咨询</div>
        <div class="zjzx_content" @click="ljyy(8)">
          <div class="zjzx_cons" v-for="(item, index) in 1" :key="index">
            <img class="zjzx_consimg" src="@/assets/home/ljyy.png" alt="">
            <div class="zjzx_cons_con">
              <img src="@/assets/home/teacher.png" alt="">
              <div class="zjzx_cons_context">
                <span>张老师</span>
                <div class="teacher_list">
                  <p>新高考报考指导</p>
                </div>
                <p class="teacher_1">DISC个性测验是国外企业广泛应用的一种人格测验，用于测查、评估和帮助人们改善其行为方式、人际关系、工作绩效、团队合作、领导风</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getNew, getNewList, getTestList, getUser } from "@/untlis/api";


export default {
  name: 'HomeView',
  data() {
    return {
      select: '1',//首页banner下拉栏默认选中
      // 首页banner多选框列表
      Arr: [],
      disabled: false,
      valNum: '',
      tall: [{
        name: '物理',//首页banner多选框文案
        select: false,//首页banner多选框是否默认选中
        disabled: null//首页banner多选框是否禁用
      }, {
        name: '化学',
        select: false,
        disabled: null
      }, {
        name: '生物',
        select: false,
        disabled: null
      }, {
        name: '历史',
        select: false,
        disabled: null
      }, {
        name: '政治',
        select: false,
        disabled: null
      }, {
        name: '地理',
        select: false,
        disabled: null
      }],
      fruitlIST: [],
      // 首页banner下方list
      bannerList: [{
        imgUrl: require('@/assets/home/find.png'),
        name: '找大学',
        url: '/CollegeQuery'
      }, {
        imgUrl: require('@/assets/home/find1.png'),
        name: '查专业',
        url: '/Major/Specialities'
      }, {
        imgUrl: require('@/assets/home/find2.png'),
        name: '查职业',
        // url: '/CollegeQuery'
        open: '1'
      }, {
        imgUrl: require('@/assets/home/zy.png'),
        name: '适合职业',
        // url: '/CollegeQuery'
        open: '1'
      }, {
        imgUrl: require('@/assets/home/lq.png'),
        name: '录取概率',
        // url: '/CollegeQuery'
        open: '1'
      }, {
        imgUrl: require('@/assets/home/find3.png'),
        name: '查位次',
        url: '/Precedence'
      }, {
        imgUrl: require('@/assets/home/find4.png'),
        name: '查批次线',
        url: '/Batch'
      }, {
        imgUrl: require('@/assets/home/zj.png'),
        name: '问专家',
        url: 'list?id=8'
      }],
      // 首页内容tab切换默认选中
      currentTab: 0,
      currentClass: 0,
      // 首页内容切换tab控制
      tabList: '',
      tabList2: [''],
      value: 3, // 教育机构评分
      typename: '',
      newlist: '',
      TestList: '',// 测评
    }

  },
  created() {
    // console.log('批次当前初始化选中：', this.select);
  },
  mounted() {
    this.getNew()
    this.toggleTab(0)
    this.getTestList()
    this.valNum = sessionStorage.getItem('scoreTotal') || ''
    const physics = sessionStorage.getItem('physics') || ''
    const chemistry = sessionStorage.getItem('chemistry') || ''
    const biology = sessionStorage.getItem('biology') || ''
    const history = sessionStorage.getItem('history') || ''
    const politics = sessionStorage.getItem('politics') || ''
    const geography = sessionStorage.getItem('geography') || ''
    const arr = [physics, chemistry, biology, history, politics, geography]
    this.tall.forEach((item, index) => {
      // item.select = (arr[index] !=='' && arr[index] !== null) ? true : false
      console.log(arr[index] != null);
      if ((arr[index] !== '' && arr[index] !== null)) {
        item.select = true
        this.Arr.push(item.name)
      }
    })
    console.log(this.tall);
  },
  methods: {
    ljyy(id) {
      this.$router.push('/list?id=' + id)
    },
    routerjump(url, open) {
      this.$router.push(url)
      if (open) {
        this.$alert('该功能暂未上线', '新方舟升学', {
          confirmButtonText: '确定'
        });
      }
    },
    // 首页banner内容多选框
    FNcheck(event, index) {
      if (this.Arr.length >= 3) {
        this.disabled = true
      }
    },
    back() {
      this.Arr = []
      this.disabled = false
    },
    // 首页banner下拉栏选中
    isMileageCardOrder(val) {
      // console.log(val);
      this.select = val
      console.log('批次当前选中：', this.select);
    },
    // 首页内容tab切换控制
    toggleTab(current) {
      this.currentTab = current;
      this.currentClass = current;
      this.bus.$emit("loading", true);
      getNewList({ page: '1', rows: '10', Newstype: current + 1 }).then(res => {
        this.bus.$emit("loading", false);
        console.log(res);
        this.newlist = res.data.rows
      })
    },
    // 初始化获取测评列表   
    getTestList() {
      getTestList({ EvalType: '0' }).then(res => {
        console.log(res);
        this.TestList = res.data.data
      })
    },
    //首页点击填报
    handle() {
      if (!this.valNum) return this.$message({
        message: '请填写完成的信息',
        type: 'warning',
        offset: '60'
      });
      getUser().then(res => {
        sessionStorage.setItem('tallList', JSON.stringify(this.Arr))
        this.$router.push('/Aspiration')
      })
    },

    getNew() {
      getNew({ typename: '新闻分类' }).then(res => {
        console.log(res);
        this.tabList = res.data.data
        console.log(this.tabList);
      })
    },
    jump(id) {
      this.$router.push('/News/NewList?id=' + id)
    },
    go(id, name, isTest) {
      if (isTest == '1') {
        console.log('ce');
        this.$router.push('/Assessment/result?id=' + id)
      } else {
        this.$router.push('/Assessment/assessment?id=' + id + '&name=' + name)
      }

    }
  }
}
</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: auto;
  background-color: #ffffff;

  .home_banner {
    position: relative;
    width: 100%;
    height: 7.82rem;
    background-color: #3A48FF;
    background-image: url('@/assets/home/banner.png');
    background-size: 100% 100%;

    .home_b_content {
      position: absolute;
      width: 3.90rem;
      // height: 3.54rem;
      background: #FFFFFF;
      border-radius: 0.08rem;
      left: 3.15rem;
      top: 1.43rem;
      padding: 0 0.49rem 0.3rem;
      box-sizing: border-box;

      .home_content_title {
        font-size: 0.20rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        // line-height: 58px;
        margin-top: 0.33rem;
        // margin-left: 0.49rem;
        text-align: left;
      }

      .batch {
        font-size: 0.16rem;
        margin-top: 0.23rem;
        margin-bottom: 0.23rem;
        float: left;
        width: 100%;
        text-align: left;

        /deep/.el-input__inner {
          height: 0.36rem;
        }

        .input_batch {
          width: 100%;
          height: auto;
          margin-left: -0.05rem;
          display: inline-block;

          /deep/.el-input__inner {
            width: 100%;
            height: 0.36rem;
          }
        }

        /deep/.el-select {
          width: 1.8rem;
          height: 0.32rem !important;
        }
      }

      .choose {
        font-size: 0.16rem;
        // margin-top: 0.23rem;
        width: 3.2rem;
        display: flex;

        .input_content {
          width: 1.8rem;
          margin-left: 0.1rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          /deep/.el-button {
            padding: 0.1rem 0.1rem;
            width: 100%;
            // background: #3974F2;
          }

          .el-button--primary:focus,
          .el-button--primary:hover {
            height: auto;
          }
        }

        .label_input {
          width: calc(100% /3);
          margin-bottom: 0.05rem;
        }

        /deep/.el-checkbox-group {
          width: 2.4rem;
          height: 0.78rem;
          display: flex;
          flex-wrap: wrap;
          // float: right;
          font-size: 0.16rem;
        }

        /deep/.el-checkbox {
          margin-right: 0.1rem;
          font-size: 0.14rem;
        }
      }

      .btn {
        width: 2.80rem;
        height: 0.48rem;
        background: #3974F2;
        border-radius: 0.08rem;
        color: #FFFFFF;
        font-size: 0.18rem;
        margin-top: 0.9rem;
        line-height: 0.48rem;
        text-align: center;
        cursor: pointer;
      }
    }

    .home_b_lists {
      width: 100%;
      height: 1.84rem;
      background: rgba(62, 101, 200, 0.85);
      border-radius: 0.4rem 0.4rem 0px 0px;
      position: absolute;
      bottom: 0;
      padding: 0 3rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .home_b_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        img {
          width: 0.9rem;
          height: 0.9rem;
          margin-bottom: 0.09rem;
        }

        span {
          font-size: 0.18rem;
          color: #FFFFFF
        }
      }
    }
  }

  .home_content {

    padding-top: 0.4rem;
    box-sizing: border-box;

    .gkzx {
      width: 100%;
      padding: 0 2.09rem;
      box-sizing: border-box;
      // height: 11.52rem;
      background-color: #FFFFFF;
      margin-bottom: 0.5rem;

      .gkzx_title {
        font-size: 0.24rem;
        color: #333333;
        float: left;
        margin-right: 0.82rem;
      }

      .gkzx_title::after {
        content: '';
        border-bottom: 0.05rem solid #3A78F3;
        display: block;
        width: 0.56rem;
        box-shadow: 0.03rem 0.03rem 0.05rem #6b6a6a;
        margin-top: 0.08rem;
      }

      .tab {
        height: 0.4rem;

        .tab_button {
          line-height: 0.37rem;
          font-size: 0.18rem;
          display: flex;
          cursor: pointer;
          color: #838383;

          .active {
            color: #3974F2;
          }

          div {
            padding: 0 0.26rem 0 0;
          }
        }
      }

      .tab_con {


        .tab_con_list {
          padding: 0.25rem 0 0 0;

          // box-sizing: border-box;
          width: 100%;
          height: 1.2rem;
          display: flex;
          align-items: center;
          border-bottom: 0.01rem dashed #999999;

          .tab_con_list_img {
            width: 1.9rem;
            height: 1rem;
            margin-right: 0.1rem;
          }

          .tab_con_cons {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-around;

            .tab_cons_title {
              display: flex;
              align-items: center;

              img {
                width: 0.54rem;
                height: 0.32rem;
              }

              p {
                font-size: 0.16rem;
                color: #444444;
                cursor: pointer;
              }

              p:hover {
                color: #3974F2;
              }
            }

            .tab_cons_con {
              font-size: 0.14rem;
              color: #999999;
              line-height: 0.23rem;
              display: inline-block;
              text-align: left;
            }

            .tab_cons_data {
              font-size: 0.12rem;
              color: #999999;
              text-align: left;
            }
          }
        }

        /deep/.el-pagination {
          float: right;
          display: flex;
          height: 0.77rem;
          align-items: center;
        }
      }
    }

    .jyjg {
      background-color: #f6f6f6;
      padding: 0 2.09rem;
      box-sizing: border-box;
      // height: 10rem;
      padding-top: 0.4rem;

      .jyjg_title {
        font-size: 0.24rem;
        color: #333333;
        float: left;
        margin-right: 0.82rem;
      }

      .jyjg_title::after {
        content: '';
        border-bottom: 0.05rem solid #3A78F3;
        display: block;
        width: 0.56rem;
        box-shadow: 0.03rem 0.03rem 0.05rem #6b6a6a;
        margin-top: 0.08rem;
      }

      .jyjg_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.54rem;

        .jyjg_list {
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          margin-bottom: 1rem;
          transition: all 0.5s;

          .jyjg_listimg {
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            top: -40%;
          }

          .jyjg_lists {
            width: 2.84rem;
            height: 1.67rem;
            background-color: #ffffff;
            border-radius: 0.1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-top: 0.3rem;
            box-sizing: border-box;

            span {
              font-size: 0.16rem;
              color: #444444;
            }

            /deep/.el-rate__icon {
              font-size: 0.3rem;
            }
          }
        }

        .jyjg_list:hover {
          transform: translateY(-0.15rem);
        }
      }
    }

    .cptx {
      padding: 0 2.09rem;
      box-sizing: border-box;
      // height: 10rem;
      padding-top: 0.4rem;

      .cptx_title {
        font-size: 0.24rem;
        color: #333333;
        float: left;
        margin-right: 0.82rem;
        margin-bottom: 0.5rem;
      }

      .cptx_title::after {
        content: '';
        border-bottom: 0.05rem solid #3A78F3;
        display: block;
        width: 0.56rem;
        box-shadow: 0.03rem 0.03rem 0.05rem #6b6a6a;
        margin-top: 0.08rem;
      }

      .tab {
        height: 0.4rem;

        .tab_button {
          line-height: 0.37rem;
          font-size: 0.18rem;
          display: flex;
          cursor: pointer;
          color: #838383;

          .active {
            color: #3974F2;
          }

          div {
            padding: 0 0.26rem 0 0;
          }
        }
      }

      .tab_con {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        margin-top: 0.38rem;

        .cptx_cons {
          width: 3.60rem;
          height: 2.65rem;
          background: #5EBDB2;
          border-radius: 0.1rem;
          position: relative;
          transition: all 0.5s;
          margin-right: 0.38rem;
          font-size: 0.16rem;
          padding: 0 0.23rem;
          padding-top: 0.31rem;
          box-sizing: border-box;
          text-align: left;
          color: #ffffff;
          overflow: hidden;
          cursor: pointer;

          p {
            text-align: left;
            margin-bottom: 0.29rem;
          }

          .cptxhtml {
            font-size: 0.14rem;
          }

          .cptx_cons_bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.6rem;
            background: #CBE1DE;
            border-radius: 0px 0px 0.04rem 0.04rem;
            padding: 0 0.23rem;
            box-sizing: border-box;
            line-height: 0.6rem;
            font-size: 0.14rem;
            color: #777777;
          }

          .card {
            position: absolute;
            width: 100%;
            height: 100%;
            // padding: 20px;
            text-align: center;
            /* 默认下移+隐藏 */
            // transform: translateY(1rem);
            top: 2.65rem;
            left: 0;
            opacity: 0;
            transition: 0.5s;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            .cardbtn {
              width: 1rem;
              height: 0.35rem;
              background-color: #ffffff;
              line-height: 0.35rem;
              text-align: center;
              border-radius: 0.1rem;
              color: #555555;
              font-size: 0.14rem;
              cursor: pointer;
            }

            .cardbtn:hover {
              background-color: #CBE1DE;
              color: #ffffff;
            }
          }
        }

        // .cptx_cons:hover {
        //   transform: translateY(-0.15rem);
        //   // transform: rotate(10deg);
        // }

        .cptx_cons:hover .card {
          // transform: translateY(0);
          top: 0;
          opacity: 1;
        }
      }
    }

    .zjzx {
      padding: 0 2.09rem;
      box-sizing: border-box;
      // height: 10rem;
      padding-top: 0.4rem;

      .zjzx_title {
        font-size: 0.24rem;
        color: #333333;
        float: left;
        margin-right: 0.82rem;
      }

      .zjzx_title::after {
        content: '';
        border-bottom: 0.05rem solid #3A78F3;
        display: block;
        width: 0.56rem;
        box-shadow: 0.03rem 0.03rem 0.05rem #6b6a6a;
        margin-top: 0.08rem;
      }

      .zjzx_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1.54rem;

        .zjzx_cons {
          width: 4.86rem;
          // height: 2.70rem;
          background: #FFFFFF;
          box-shadow: 0.01rem 0.09rem 0.29rem 0px rgba(0, 0, 0, 0.09);
          border-radius: 0.01rem;
          position: relative;
          margin-bottom: 1rem;
          padding-top: 0.5rem;
          padding-bottom: 0.4rem;
          box-sizing: border-box;
          transition: all 0.5s;
        }

        .zjzx_cons:hover {
          transform: translateY(-0.15rem);
          // transform: rotate(360deg);
        }

        .zjzx_consimg {
          width: 1.08rem;
          height: 1.2rem;
          position: absolute;
          top: -25%;
          left: 40%;
        }

        .zjzx_cons_con {
          display: flex;
          justify-content: center;

          img {
            width: 1.25rem;
            height: 1.66rem;
            margin-right: 0.18rem;
          }

          .zjzx_cons_context {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            span {
              font-size: 0.16rem;
              color: #333333;
              text-align: left;
            }

            .teacher_list {
              font-size: 0.14rem;
              color: #555555;
              width: 2.89rem;
              // height: 0.38rem;
              display: flex;
              flex-wrap: wrap;
              // justify-content: space-between;
              float: left;

              p {
                min-width: 1rem;
                margin-right: 0.1rem;
              }
            }

            .teacher_1 {
              width: 2.89rem;
              font-size: 0.12rem;
              color: #999999;
              text-align: left;
            }
          }
        }
      }

    }
  }
}
</style>
