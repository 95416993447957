<template>
    <div class="footer">
        <div class="footer_top">
            <div class="footer_l">
                <ul class="footer_l_text">
                    <div>关于新方舟</div>
                    <li>关于我们</li>
                    <li>文化与价值观</li>
                    <li>发展历程</li>
                    <li>管理团队社会责任</li>
                </ul>
                <ul class="footer_l_text">
                    <div>咨询时间</div>
                    <li>热线咨询时间: 周一至周五 10:00-18:00</li>
                    <li>门店咨询时间: 周一至周日 9:00-22:00</li>
                    <li style="display: flex">咨询热线:
                        <div> 13121079988 <br>
                            17353946415 <br>
                            18910213520
                        </div>
                    </li>
                </ul>
                <ul class="footer_l_text">
                    <div>联络我们</div>
                    <li @click="jump">联系我们</li>
                    <!-- <li>开放平台</li> -->
                    <!-- <li>司机加盟</li> -->
                    <!-- <li>人才招聘</li> -->
                </ul>
            </div>
            <div class="footer_r">
                <div class="footer_r_title">关注我们</div>
                <div class="footer_r_img">
                    <img src="@/assets/home/5.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <p>2023新方舟教育科技有限公司@版权所有 <a style="color: #d3d3d3;"
                    href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2020038822号-3</a></p>
            <p>新方舟教育提供技术支持</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        jump() {
            this.$router.push('/list?id=8')
        }
    }
}
</script>
<style scoped lang="less"> .footer {
     position: absolute;
     min-width: 1200px;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     //height: 3.4rem;
     background-color: #333333;
     padding-left: 2.1rem;
     padding-right: 2.1rem;
     padding-top: 0.44rem;
     box-sizing: border-box;

     /* bottom: 0; */
     .footer_top {
         width: 100%;
         height: 2.4rem;
         display: flex;
         padding-left: 0.57rem;
         padding-right: 0.53rem;
         box-sizing: border-box;
         justify-content: space-between;
         border-bottom: 0.01rem solid #656565;
         margin-bottom: 0.15rem;

         .footer_l {
             width: auto;

             text-align: left;
             display: flex;

             .footer_l_text {
                 margin-right: 1.1rem;
                 line-height: 0.3rem;

                 div {
                     font-size: 0.16rem;
                     color: #ffffff;
                     margin-bottom: 0.2rem;
                 }

                 li {
                     list-style: none;
                     font-size: 0.14rem;
                     color: #cdcdcd;
                 }
             }

         }

         .footer_r {

             // float: right;
             .footer_r_title {
                 font-size: 0.16rem;
                 color: #ffffff;
                 text-align: left;
                 margin-bottom: 0.15rem;
                 margin-left: 0.1rem;
             }

             .footer_r_img {
                 width: 2.7rem;
                 display: flex;
                 justify-content: space-between;

                 img {
                     width: 1.1rem;
                     height: 1.1rem;
                 }
             }
         }
     }

     .footer_bottom {
         font-size: 0.14rem;
         color: #d3d3d3;
     }
 }
</style>
<script setup>
</script>