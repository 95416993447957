// http.js
import router from "@/router";
import axios from "axios";
// axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
//   "application/x-www-form-urlencoded;charset=UTF-8";
console.log('router',router);
// get 请求
function httpGet({ url, params = {} }) {
  return new Promise((resolve, reject) => {
    // plugTool.JLoading();
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        // plugTool.JHideLoading();
      });
  });
}
// post请求
function httpPost({ url, data = {}, params = {} }) {
  return new Promise((resolve, reject) => {
    // let loading = Loading.service({
    //   lock: true,
    //   text: '拼命加载中',
    //   background: 'rgba(0,0,0,0.7)',
    //   target:'#contents'
    // });

    axios({
      url,
      method: "post",
      // 发送的数据
      data,
      // url参数
      params,
    })
      .then((res) => {
        resolve(res);

      })
      .catch((err) => {
        reject(err);

      })
      .finally(() => {

        // plugTool.JHideLoading();
        // loading.close();
      });
  });
}

// 请求拦截
axios.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;

  // else {
  //   // console.log(Vue);
  //   router.replace({
  //     path: "/RegisterLogin",
  //     query: { redirect: router.currentRoute.fullPath },
  //   });
  // }
});
// 响应拦截
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.log(error);
    if (error.response.status == 401) {
      // this.$message({
      //   message: '登录信息过期，请重新登录~',
      //   type: 'warning'
      // });
      localStorage.removeItem("token");
      sessionStorage.clear();
      // 
      router.push('/RegisterLogin')
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { httpGet, httpPost };
