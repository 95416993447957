import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Specialities:""
  },
  getters: {
  },
  mutations: {
    setStore(store,payload){
      store.Specialities=payload
    }
  },
  actions: {
  },
  modules: {
  }
})
