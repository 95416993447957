import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// axios 接口请求
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
// ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false;

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper)

Vue.prototype.bus = new Vue();
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
