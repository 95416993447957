<template>
  <div id="app">
    <NavBar v-if="$route.meta.NavBar" :userName="userName" :headImageUrl="headImageUrl" :state="state"></NavBar>
    <div id="contents">
      <div id="content">
        <!-- 指定组件的位置 -->
        <router-view :key="key"></router-view>
        <!-- btnFlag 控制图片显示隐藏 -->
        <!-- backTop 回顶部的方法 -->
        <img v-if="btnFlag && $route.meta.top == true" class="go-top" src="@/assets/top.png" @click="backTop">
        <img v-if="$route.meta.vip == true" class="go-vip" src="@/assets/vip2.png" @click="govip">
      </div>
    </div>
    <!-- 加载页 -->
    <Loading></Loading>
    <Footer v-if="$route.meta.footer"></Footer>
  </div>
</template>
<script>
import { getUser } from "@/untlis/api";
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Loading from '@/components/loading.vue';
export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    Loading
  },
  data() {
    return {
      btnFlag: false,
      userName: '',
      headImageUrl: '',
      state: ''
    }
  },
  created() {
    // this.init()
    // this.getUser()

    window.addEventListener('scroll', this.scrollToTop)
    window.addEventListener('pageshow', function (event) {
      console.log(event);
    })
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },

  methods: {
    // debugger
    init() {
      setInterval(function () {
        check()
      }, 1000);
      var check = function () {
        function doCheck(a) {
          if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
            (function () { }
            ["constructor"]("debugger")())
          } else {
            (function () { }
            ["constructor"]("debugger")())
          }
          doCheck(++a)
        }
        try {
          doCheck(0)
        } catch (err) { }
      };
      check();
    },
    // 点击图片回到顶部方法
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 滚动到一定位置出现
    scrollToTop() {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 60) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    govip() {
      this.$router.push('/vip')
    },

    // getUser(){
    //   getUser().then(res => {
    //     console.log(res);

    //     this.userName = res.data.data.userName
    //     this.headImageUrl = res.data.data.headImageUrl
    //   })
    // }

  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    }
  }

}
</script>
<style lang="less">
@import "./assets/font/font.css";

* {
  padding: 0;
  margin: 0;

  font-family: "zlj";
}

// 隐藏浏览器滚动条 Chorme
// body::-webkit-scrollbar {
//   display: none;
// }

// // 隐藏浏览器滚动条 IE/Edge
// body {
//   -ms-overflow-style: none;
// }

// html {
//   overflow: -moz-hidden-unscrollable;
//   /*注意！若只打 hidden，chrome 的其它 hidden 会出问题*/
//   height: 100%;
// }

// // 隐藏浏览器滚动条 Firefox
// body {
//   height: 100vh;
//   width: calc(100vw + 18px); // 浏览器滚动条的长度大约是 18px
//   overflow: auto;
// }

html,
body {
  height: 100%;
  margin: 0;
}

a {
  text-decoration: none;
  color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
}

#contents {
  display: flex;
  justify-content: center;
}

#content {
  display: flex;
  width: 100%;
  // height: 1400px;
  min-height: calc(100vh - 0.66rem);
  background-color: #f0f2f3;
}

.go-top {
  position: fixed;
  right: 0.3rem;
  bottom: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  z-index: 9999;
  cursor: pointer;
}

.go-vip {
  position: fixed;
  right: 0.3rem;
  bottom: 1.2rem;
  width: 0.6rem;
  height: 0.6rem;
  z-index: 9999;
  cursor: pointer;
}
/deep/.el-message{

}
</style>
