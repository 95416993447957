// api.js
import { httpGet, httpPost } from "./http";
const url = 'http://114.115.215.121:9991'//http://114.115.215.121:9991https://zhiyuandeng.com/
// 登录
const login = (params = {}) =>
    httpPost({
        url: `${url}/api/User/SigninUser`,
        params,
    });
// 获取验证码
const getCode = (params = {}) =>
    httpPost({
        url: `${url}/api/User/verificationcode`,
        params,
    });
// 用户未修改过密码 强制登录
const getlogin = (params = {}) =>
    httpPost({
        url: `${url}/api/User/LoginUser`,
        params,
    });
// code换取token
const gettoken = (params = {}) =>
    httpGet({
        url: `${url}/api/User/webToken`,
        params,
    });
//
const getUser = (params = {}) =>
    httpPost({
        url: `${url}/api/User/GetUserInfo`,
        params,
    });
// 查专业初始化
const MajorTypeInit = (data = {}) =>
    httpPost({
        url: `${url}/api/Major_type/GetlistMajor4AppAll`,
        // data,
    });
// 查位次
const SegmentTable = (params) =>
    httpPost({
        url: `${url}/api/Segment_table/GetList`,
        params
    });
// 查批次
const batchTable = (params) =>
    httpPost({
        url: `${url}/api/Batchpoint/GetList`,
        params,
    });

// 查位年份
const getYear = (params) =>
    httpGet({
        url: `${url}/api/Sys_DictionaryList/Typelist`,
        params: {
            type: "年份"
        },
    });
// 找大学 查位次 查批次地址列表
const addressList = () => {
    return httpGet({
        url: `${url}/api/Region/schoolProvince`
    })
}
//点击智能填报
const AIhandle = (params) => {
    return httpPost({
        url: `${url}/api/User/EditUserApp`,
        params
    })
}
// 获取新闻类型
const getNew = (params) => {
    return httpGet({
        url: `${url}/api/Sys_DictionaryList/newsType`,
        params
    })
}
//获取新闻详情列表信息
const getNewList = (params) => {
    return httpPost({
        url: `${url}/api/News/GetNewslist`,
        params
    })
}
// 获取新闻详情
const getNewxq = (params) => {
    return httpPost({
        url: `${url}/api/News/newsxq`,
        params
    })
}
// 获取测评列表
const getTestList = (params) => {
    return httpPost({
        url: `${url}/api/Eval_Table/EvalList`,
        params
    })
}
// 获取答题
const getDiscList = (params) => {
    return httpPost({
        url: `${url}/api/DISC_Table/DISClist`,
        params
    })
}
// 提交答题
const sub = (params) => {
    return httpPost({
        url: `${url}/api/User_DISCResult/AddDISCResult`,
        params
    })
}
const sub1 = (params) => {
    return httpPost({
        url: `${url}/api/User_DISCResult/AddMBITResult`,
        params
    })
}
const sub2 = (params) => {
    return httpPost({
        url: `${url}/api/User_DISCResult/AddHLDResult`,
        params
    })
}
/*  AddDISCBack  disc结果返回
    AddHLDBack  
    AddHLDBack  **/

const AddDISCBack = (params) => {
    return httpPost({
        url: `${url}/api/Disc_result/AddDiscBack`,
        params
    })
}
const AddMBITBack = (params) => {
    return httpPost({
        url: `${url}/api/User_MBITRsult/AddMBITBack`,
        params
    })
}
const AddHLDBack = (params) => {
    return httpPost({
        url: `${url}/api/User_HLDResult/AddHLDBack`,
        params
    })
}
// AddMBITResult/AddDISCResult

const schoolType = (parmas) => {
    return httpGet({
        url: `${url}/api/Sys_DictionaryList/Typelist?type=` + parmas
    })
}
const newSchoolList = (data) => {
    return httpPost({
        url: `${url}/api/School_college/websearchSchoool`,
        params: { ...data }
    })
}
const getSchoolDetails = (params) => {
    return httpGet({
        url: `${url}/api/School_college/GetSchoolDetails`,
        params: { ...params }
    })
}
// 查询专业类别
const GetlistMajor4App = (data) => {
    return httpPost({
        url: `${url}/api/Major_type/GetlistMajor4App`,
        params: { ...data }
    })
}
// 查询专业详情
const GetDetails = (params) => {
    return httpPost({
        url: `${url}/api/Major_type/major_typeDetails`,
        params,
    })
}
// 
// /api/Major_college_copy1_copy1/Major_collegelistApp
const Getcollegelist = (params) => {
    return httpPost({
        url: `${url}/api/Major_college_copy1_copy1/Major_collegelistWeb`,
        params,
    })
}
// 按院校查
const GetSchoollistApp = (params) => {
    return httpPost({
        url: `${url}/api/School_college/GetSchoollistApp`,
        params,
    })
}

//学费下拉 DictionaryList
const DictionaryList = (params) => {
    return httpPost({
        url: `${url}/api/Sys_DictionaryList/Typelist?type=学费`,
        params,
    })
}
// api/Major_type/GetlistMajor4App 专业类别下拉
const GetlistMajor4 = (params) => {
    return httpPost({
        url: `${url}/api/Major_type/GetlistMajor4App`,
        params,
    })
}
//省份下拉 /api/Region/schoolProvince
const schoolProvince = (params) => {
    return httpPost({
        url: `${url}/api/Region/schoolProvince`,
        params,
    })
}
//院校类别   /api/Sys_DictionaryList/Typelist?type=院校类别
const TypelistType = (params) => {
    return httpPost({
        url: `${url}/api/Sys_DictionaryList/Typelist?type=院校类别`,
        params,
    })
}
// 办校性质  /api/Sys_DictionaryList/Typelist?type=办学性质
const categoryList = (params) => {
    return httpPost({
        url: `${url}/api/Sys_DictionaryList/Typelist?type=办学性质`,
        params,
    })
}

// 获取全部志愿
const wishlist = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/my_wishlistgroup`,
        params,
    })
}

//移除志愿分组 movegroup
const movegroup = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/wishNumberdel`,
        params,
    })
}

//获取志愿单中信息  wishNumberlistV2
const wishNumberlistV2 = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/wishNumberlistV2`,
        params,
    })
}

//移除志愿学校 movegroup
const moveschool = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/RemovewishSchool`,
        params,
    })
}

// 根据学校查专业
const SchooolsearchMajor = (params) => {
    return httpPost({
        url: `${url}/api/Major_college_copy1_copy1/SchooolsearchMajor_collegeV2`,
        params,
    })
}

// 保存志愿单
const savemajor = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/my_wish`,
        params,
    })
}
// 改变支援列表
const changeAjor = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/wish_move`,
        params,
    })
}
const changeAjorGroup = (params) => {
    return httpPost({
        url: `${url}/api/My_wish/wish_movegroup`,
        params,
    })
}
// vip列表
const Getcard = (params) => {
    return httpPost({
        url: `${url}/api/CardType/Cardlis`,
        params,
    })
}
//充值
const GetQRCode = (params) => {
    return httpPost({
        url: `${url}/api/Order_commodity/GetQRCode`,
        params,
    })
}

const QueryOrder = (params) => {
    return httpPost({
        url: `${url}/api/Order_commodity/Search`,
        params,
    })
}
// 卡密激活列表
const CamilleListApi = (params) => {
    return httpPost({
        url: `${url}/api/Member_Card/CardListUser`,
        params,
    })
}

// 卡密激活列表
const CamilleAddApi = (params) => {
    return httpPost({
        url: `${url}/api/Member_Card/Card_Activation`,
        params,
    })
}

// 收藏学校列表
const schoolLsitApi = (params) => {
    return httpPost({
        url: `${url}/api/User_follow_school/Getfollow_school`,
        params,
    })
}
//收藏专业列表
const specialityLsitApi = (params) => {
    return httpPost({
        url: `${url}/api/User_follow_major/Getfollow_major`,
        params,
    })
}
// speciality
//判断学校是被关注
const isSchoolApi = (params) => {
    return httpPost({
        url: `${url}/api/User_follow_school/Getfollow_schoolUser`,
        params,
    })
}
//关注学校/api/User_follow_school/Removefollow_school
const addschoolApi = (params) => {
    return httpPost({
        url: `${url}/api/User_follow_school/Addfollow_school`,
        params,
    })
}
//关注学校/api/User_follow_school/Removefollow_school
const deleteschoolApi = (params) => {
    return httpPost({
        url: `${url}/api/User_follow_school/Removefollow_school`,
        params,
    })
}
const getxx = (params) => {
    return httpPost({
        url: `${url}/api/Major_college_copy1_copy1/SchooolsearchMajor_collegeV2`,
        params,
    })
}

const fkcode = (params) => {
    return httpPost({
        url: `${url}/api/Feedback/AddFeedback`,
        params,
    })
}
//判断是否关注过/api/User_follow_major/Getfollow_majorUser
const GetfollowMajorUser = (params) => {
    return httpGet({
        url: `${url}/api/User_follow_major/Getfollow_majorUser`,
        params: { ...params }
    })
}
// 关注专业/api/User_follow_major/Addfollow_major
const GetfollowMajor = (params) => {
    return httpPost({
        url: `${url}/api/User_follow_major/Addfollow_major`,
        params: { ...params }
    })
}
// 取消关注专业 /api/User_follow_major/Removefollow_major
const RemovefollowMajor = (params) => {
    return httpGet({
        url: `${url}/api/User_follow_major/Removefollow_major`,
        params: { ...params }
    })
}
// vip购买订单查询
const getOrderList = (params) => {
    return httpGet({
        url: `${url}/api/Order_commodity/Order_commodityList`,
        params: { ...params }
    })
}
//
// /api/From_major_college_copy1_copy1/Major_collegesearchEnrollmentinformation
const search = (params) => {
    return httpGet({
        url: `${url}/api/From_major_college_copy1_copy1/Major_collegesearchEnrollmentinformationWeb`,
        params: { ...params }
    })
}
// 上传头像
const upload = (params) => {
    return httpPost({
        url: `${url}/api/Sys_User/images`,
        params: { ...params }
    })
}
const getalipay = (params) => {
    return httpGet({
        url: `${url}/api/Order_commodity/alipaypc`,
        params: { ...params }
    })
}
export {
    login, getCode, MajorTypeInit, SegmentTable, getYear,getlogin,gettoken,
    addressList, schoolType, getSchoolDetails, newSchoolList,
    AIhandle, getNew, getNewList, GetlistMajor4App, batchTable,GetfollowMajorUser, GetfollowMajor, RemovefollowMajor,
    GetDetails, getTestList, getDiscList, getNewxq, getUser, sub,
    AddDISCBack, Getcollegelist, sub1, sub2, Getcard, GetQRCode, GetSchoollistApp, DictionaryList,
    GetlistMajor4, schoolProvince, TypelistType, categoryList, wishlist, wishNumberlistV2, movegroup,
    moveschool, SchooolsearchMajor, savemajor, AddMBITBack, AddHLDBack, QueryOrder,
    CamilleListApi,CamilleAddApi,schoolLsitApi,specialityLsitApi,isSchoolApi,addschoolApi,deleteschoolApi,getxx,fkcode,getOrderList,search
    ,upload,changeAjor,changeAjorGroup,getalipay
};
