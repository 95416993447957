import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      // 控制导航栏在页面是否显示
      NavBar: true,
      // 控制底部栏是否显示
      footer:true,
      top:true,
      vip:true
    },
  },
  {
    path: "/loading",
    name: "loading",
    component: ()=>import('../views/loading.vue'),
    meta: {
      // 控制导航栏在页面是否显示
      NavBar: false,
      // 控制底部栏是否显示
      footer:false,
      top:true
    },
  }, {
    path: "/alipay",
    name: "alipay",
    component: ()=>import('../views/alipay.vue'),
    meta: {
      // 控制导航栏在页面是否显示
      NavBar: false,
      // 控制底部栏是否显示
      footer:false,
      top:true
    },
  },{
    path: "/list",
    name: "list",
    component: ()=>import('../views/list.vue'),
    meta: {
      // 控制导航栏在页面是否显示
      NavBar: false,
      // 控制底部栏是否显示
      footer:false,
      top:true
    },
  },
  // 注册登录
  {
    path: "/RegisterLogin",
    name: "RegisterLogin",
    component: () =>
        import("../views/RegisterLogin.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () =>
        import("../views/demo.vue"),
  },
  // 找大学
  {
    path: "/CollegeQuery",
    name: "CollegeQuery",
    component: () =>
        import("../views/CollegeQuery.vue"),
    children:[{
      path: "/",
      name: "CollegeQuery",
      redirect:"/CollegeQuery/query"
    },{
      path: "/CollegeQuery/query",
      name: "query",
      meta: {
        NavBar: true,
        // needlogin: false,
        footer:false,
        top:true,
        vip:true
      },
      component: () =>
          import( "../views/CollegeQuery/query.vue"),
    },{
      path: "/CollegeQuery/university",
      name: "university",
      meta: {
        NavBar: true,
        // needlogin: true,
        footer:false,
        top:true,
        vip:true
      },
      component: () =>
          import( "../views/CollegeQuery/university.vue"),
    }],
    meta: {
      NavBar: true,
      // needlogin: true,
      footer:false,
      top:true,
      vip:true
    },
  },
  // 查专业
  {
    path: "/Major",
    name: "Major",
    component: () =>
        import( "../views/Major.vue"),
    children:[{
      path: "/",
      name: "Major",
      redirect:"/Major/Specialities"
    },{
      path: "/Major/Specialities",
      name: "Specialities",
      meta: {
        NavBar: true,
        // needlogin: true,
        footer:false,
        vip:true
      },
      component: () =>
          import( "../views/Major/Specialities.vue"),
    },{
      path: "/Major/MajorQuery",
      name: "MajorQuery",
      meta: {
        NavBar: true,
        // needlogin: true,
        footer:false,
        top:false,
        vip:true
      },
      component: () =>
          import( "../views/Major/MajorQuery.vue"),
    }],
    meta: {
      NavBar: true,
      // needlogin: true,
      footer:false,
      top:false,
      vip:true
    },
  },
  {
    path: "/Aspiration",
    name: "Aspiration",

    component: () =>
        import( "../views/Aspiration.vue"),
    meta: {
      NavBar: true,
      footer:false,
      top:true,
      needlogin: true,
      vip:true
    },
  },
  {
    path: "/Precedence",
    name: "Precedence",

    component: () =>
        import( "../views/Precedence.vue"),
    meta: {
      NavBar: true,
      footer:false,
      top:true,
      vip:true
    },
  },
  {
    path: "/Batch",
    name: "Batch",

    component: () =>
        import( "../views/Batch.vue"),
    meta: {
      NavBar: true,
      footer:false,
      top:true,
      vip:true
    },
  },
  //测评中心
  {
    path: "/AssessmentCenter",
    name: "AssessmentCenter",

    component: () =>
        import( "../views/AssessmentCenter.vue"),
        children:[{
          path: "/",
          name: "Assessment",
          redirect:"/Assessment/AssessmentCenter"
        },{
          path: "/Assessment/AssessmentCenter",
          name: "AssessmentCenter",
          meta: {
            NavBar: true,
            // needlogin: true,
            footer:false,top:true,vip:true
          },
          component: () =>
              import( "../views/Assessment/AssessmentCenter.vue"),
        },{
          path: "/Assessment/assessment",
          name: "assessment",
          meta: {
            NavBar: true,
            needlogin: true,
            footer:false,
            top:true,
            vip:true
          },
          component: () =>
              import( "../views/Assessment/assessment.vue"),
        },{
          path: "/Assessment/result",
          name: "result",
          meta: {
            NavBar: true,
            needlogin: true,
            footer:false,
            top:true,

          },
          component: () =>
              import( "../views/Assessment/result.vue"),
        }],
    meta: {
      NavBar: true,
      footer:false,
      top:true
    },
  },
  {
    path: "/Information",
    name: "Information",

    component: () =>
        import( "../views/Information.vue"),
        children:[{
          path: "/",
          name: "News",
          redirect:"/News/Information"
        },{
          path: "/News/Information",
          name: "Information",
          meta: {
            NavBar: true,
            // needlogin: true,
            footer:false,
            top:true
          },
          component: () =>
              import( "../views/News/Information.vue"),
        },{
          path: "/News/NewList",
          name: "NewList",
          meta: {
            NavBar: true,
            // needlogin: true,
            footer:false,
            top:true
          },
          component: () =>
              import( "../views/News/NewList.vue"),
        }],
    meta: {
      NavBar: true,
      footer:false,
      top:true
    },
  },
  {
    path: "/Ranking",
    name: "Ranking",

    component: () =>
        import( "../views/Ranking.vue"),
    meta: {
      NavBar: true,
      footer:false,
      top:true
    },
  },
  {
    path: "/vip",
    name: "vip",

    component: () =>
        import( "../views/vip.vue"),
    meta: {
      NavBar: true,
      footer:false,
      top:true,
      needlogin: true,
    },
  },
  {
    path: "/mypage",
    name: "mypage",

    component: () =>
        import( "../views/mypage.vue"),
    meta: {
      NavBar: true,
      footer:false,
      top:true
    },
  }, {
    path: "/UserAgreement",
    name: "UserAgreement",

    component: () =>
        import( "../views/UserAgreement.vue"),
    meta: {
      NavBar: false,
      footer:false,
      top:false
    },
  }, {
    path: "/PrivacyAgreement",
    name: "PrivacyAgreement",

    component: () =>
        import( "../views/PrivacyAgreement.vue"),
    meta: {
      NavBar: false,
      footer:false,
      top:false
    },
  }, {
    path: "/PrivacyPay",
    name: "PrivacyPay",

    component: () =>
        import( "../views/PrivacyPay.vue"),
    meta: {
      NavBar: false,
      footer:false,
      top:false
    },
  }
];
const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  let { needlogin } = to.meta;
  let token = localStorage.getItem("token");
  if (needlogin) {
    if (!token) {
      next("/RegisterLogin");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
