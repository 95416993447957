<template>
    <div class="header">
        <nav>
            <router-link class="logo" to="/home">
                <img src="../assets/logo.png">
            </router-link>
            <router-link class="c" active-class="active" to="/home">首页</router-link>
            <router-link class="c" active-class="active" to="/CollegeQuery">找大学</router-link>
            <router-link class="c" active-class="active" to="/Major">找专业</router-link>
            <router-link class="c" active-class="active" to="/Aspiration">填志愿</router-link>
            <router-link class="c" active-class="active" to="/Precedence">查位次</router-link>
            <router-link class="c" active-class="active" to="/Batch">查批次</router-link>
            <router-link class="c" active-class="active" to="/AssessmentCenter">测评中心</router-link>
            <router-link class="c" active-class="active" to="/Information">高考资讯</router-link>
            <router-link class="c" active-class="active" to="/mypage" v-if="token == '1'">个人中心</router-link>
            <!--            <router-link active-class="active" to="/Ranking">高校排名</router-link>-->
            <router-link active-class="active" class="login" to="/RegisterLogin" v-if="token == '2'">
                <span>登录</span>
                <router-link active-class="active" class="reg" to="/RegisterLogin">
                    注册
                </router-link>
            </router-link>
            <router-link active-class="active" to="/mypage" v-if="token == '1'">
                <img class="tx" :src="headImageUrl?'https://zhiyuandeng.com/' + headImageUrl:require('@/assets/默认头像.png')">
              <img class="tx_vip" v-if="isvip == '1'" src="@/assets/vip/headervip.png" alt="">
                <span>{{ userName }}</span>
            </router-link>
        </nav>

    </div>
</template>

<script>
import { getUser } from '@/untlis/api'
export default {
    name: "NavBar",
    data() {
        return {
            token: '',
            userName: '',
            headImageUrl: '',
            isvip:'',
            state:''
        }
    },
    created() {
        this.getUser()
        // this.routerRefresh();
    },
    mounted() {

        this.init()
    },
    methods: {
        getUser() {
            if(localStorage.getItem('token')){
                getUser().then(res => {
                if (!res) {
                    this.state = 2
                } else {
                    this.state = 1
                }
                this.userName = res.data.data.userName
                this.headImageUrl = res.data.data.headImageUrl
              this.isvip = res.data.data.isVip
                let a = JSON.stringify(res.data.data)
                sessionStorage.setItem("user",a)
                if (!res.data.data.scoreChinese || !res.data.data.scoreEnglish || !res.data.data.scoreMath) {
                  this.$router.push('/mypage')
                }
                const {
                  scoreChinese,
                  scoreEnglish,
                  scoreMath,
                  physics,    //物理
                  chemistry,  //化学
                  biology,    //生物
                  history,    // 历史
                  geography,  //地理
                  politics,   //政治
                } = res.data.data
              console.log('res.data.data.scoreTotal', res.data.data.scoreTotal);
              if (res.data.data.scoreTotal) {
                  sessionStorage.setItem('scoreTotal', res.data.data.scoreTotal)
                  sessionStorage.setItem('scoreChinese', scoreChinese || '')
                  sessionStorage.setItem('scoreEnglish', scoreEnglish || '')
                  sessionStorage.setItem('scoreMath', scoreMath || '')
                  sessionStorage.setItem('physics', physics || '')
                  sessionStorage.setItem('chemistry', chemistry || '')
                  sessionStorage.setItem('biology', biology || '')
                  sessionStorage.setItem('history', history || '')
                  sessionStorage.setItem('geography', geography || '')
                  sessionStorage.setItem('politics', politics || '')
                }
            }).catch(error =>{
                localStorage.removeItem('token')
            })
            }
            
        },
        init() {
            let token = localStorage.getItem('token')
            let _this = this
            if (token) {
                _this.token = '1'
            } else {
                _this.token = '2'
            }
        }
    }
}
</script>

<style lang="less" scoped>
.header {
    height: 0.66rem;
    background-color: #3974F2;
    color: #ffffff;
    padding: 0 2.26rem;
    font-size: 0.16rem;
    /*吸顶效果*/
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 9999;
    box-shadow: 0 0.05rem 0.1rem -0.05rem #4B7BEE;
}

.logo {
    width: 1.9rem;
    height: 0.38rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.login {
    display: flex;
    align-items: center;
    font: normal 0.28rem Cookie, Arial, Helvetica, sans-serif;
    padding: 0 0.3rem;
    color: #ffffff;
    text-decoration: none;
}

span {
    font-size: 0.16rem;
}

img {
    width: 0.4rem;
    height: 0.4rem;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

nav a {
    padding: 0 0.2rem;
    /* width: 32px; */
    text-decoration: none;
    color: #ffffff;
    font-size: 0.16rem;
    font-weight: normal;
    opacity: 0.9;
    //display: inline-block;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}

.c {
    --c: #b7cbfd;
}

.c::before {
    content: "";
    /* 绝对定位 */
    position: absolute;
    width: 80px;
    height: 8px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    /* background: var(--c); */
    border-radius: 0 0 50% 50%;
    /* 模糊滤镜 */
    filter: blur(5px);
    /* 设置过渡 */
    transition: 0.3s;
}

.c:hover::before {
    background: var(--c);
    box-shadow: 0 0 10px var(--c),
        0 0 20px var(--c),
        0 0 30px var(--c),
        0 0 40px var(--c),
        0 0 50px var(--c);
}

nav a:last-child {
    padding-left: 0.1rem;
}

nav a:hover {
    opacity: 1;
}

.active {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    opacity: 1;
    // background: var(--c);
    
}
/* .headimg {
    margin-left: 0.8rem;
} */
.reg {
    width: 0.7rem;
    height: 0.36rem;
    background-color: #16AFF4;
    box-sizing: border-box;
    color: #fff;
    line-height: 0.36rem;
    text-align: center;
    padding: 0 !important;
    border-radius: 0.04rem;
    margin-left: 0.14rem;
}

.tx {
    border-radius: 1rem;
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0.15rem;
  position: relative;
}
.tx_vip{
  position: absolute;
  width: 0.3rem;
  height: auto;
  //background-color: #ffffff;
  top:0.36rem;
  right: 0.7rem;
}
</style>
