<template>
    <div class="wavy" v-show="loading">
        <!-- --i是自定义属性，可通过var函数调用 -->
        <span style="--i:1;">l</span>
        <span style="--i:2;">o</span>
        <span style="--i:3;">a</span>
        <span style="--i:4;">d</span>
        <span style="--i:5;">i</span>
        <span style="--i:6;">n</span>
        <span style="--i:7;">g</span>
        <span style="--i:8;">.</span>
        <span style="--i:9;">.</span>
        <span style="--i:10;">.</span>
    </div>
</template>
<script>
export default {
    name: 'loading',
    data() {
        return {
            loading: false
        }
    },
    created() {
        var that = this;
        this.bus.$on("loading", function (data) {
            that.loading = !!data;
        });
    }
}
</script>

<style scoped>
.wavy {
    /* 相对定位 */
    position: fixed;
    top: 0.66rem;
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
}

.wavy span {
    position: relative;
    display: inline-block;
    color: #3c8af0;
    font-size: 50px;
    text-transform: uppercase;
    letter-spacing: 8px;
    /* 执行动画：动画名 时长 加速后减速 无限次播放 */
    animation: wavyAnimate 1s ease-in-out infinite;
    /* 通过var函数调用自定义属性--i，在计算出动画延迟时间 */
    animation-delay: calc(0.1s * var(--i));
}

/* 定义动画 */
@keyframes wavyAnimate {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-20px);
    }

    40%,
    100% {
        transform: translateY(0);
    }
}
</style>